import { useCallback, useEffect } from 'react'
import config from 'config'
import logger from 'logger'
import localStorage from 'local-storage'
import { useSearchParams } from 'router'
import { useDevice } from 'device'
import { processError, SilentError } from 'helpers'
import { encodeBase64 } from 'crypto-helpers'
import { useReducerState } from 'hooks'

import useFacebookSDK from './useFacebookSDK'


const getTestFacebookAuth = (): fb.AuthResponse => {
  if (config.runEnv !== 'prod') {
    const facebookAuth = localStorage.getSessionItem('facebookAuth')

    if (facebookAuth) {
      // ATTN this is required for json validation
      // id, firstName, lastName, email, gender are required as strings
      return {
        accessToken: `test-${encodeBase64(JSON.stringify(facebookAuth))}`,
        expiresIn: null,
        signedRequest: null,
        userID: facebookAuth.id || null,
        grantedScopes: 'email,user_messenger_contact',
        data_access_expiration_time: 1000000000,
      }
    }
  }
}

const fbGetLoginStatus = () => new Promise<fb.AuthResponse>((resolve, reject) => {
  const testAuth = getTestFacebookAuth()

  if (testAuth) {
    resolve(testAuth)
    return
  }

  const timeout = setTimeout(() => {
    reject(new SilentError(null, '[Facebook] getLoginStatus timeout error'))
  }, 60000)

  FB.getLoginStatus(({ authResponse, status }) => {
    clearTimeout(timeout)

    if (status === 'connected') {
      logger.info(`[Facebook] user ${authResponse.userID} is connected`)

      resolve(authResponse)
      return
    }

    resolve(null)
  })
})

const fbLogin = (device: DeviceContext) => new Promise<fb.AuthResponse>((resolve, reject) => {
  const testAuth = getTestFacebookAuth()

  if (testAuth) {
    resolve(testAuth)
    return
  }

  const isTestEnvironment = config.runEnv !== 'prod'

  const options: fb.LoginOptions = {
    scope: isTestEnvironment ? 'email,user_messenger_contact' : 'email',
    messenger_page_id: isTestEnvironment ? config.facebookMessengerPageId : undefined,
  }

  if (device.browser === 'Facebook' && device.platform === 'Android') {
    delete options.scope
  }

  FB.login(({ authResponse, status }) => {
    if (authResponse) {
      logger.info(`[Facebook] user ${authResponse.userID} has logged in with status ${status}`)

      resolve(authResponse)
    }
    else {
      reject(new SilentError(null, '[Facebook] window was closed'))
    }
  }, options)
})

type Options = {
  skipLoginStatus?: boolean
}

const useFacebookAuth = (options: Options = {}) => {
  const { skipLoginStatus } = options

  const device = useDevice()
  const { isReady } = useFacebookSDK()

  const [ { auth, isFetching }, updateState ] = useReducerState({
    auth: null,
    isFetching: true,
  })

  const [ { socialButtonData } ] = useSearchParams()

  useEffect(() => {
    if (socialButtonData && config.runEnv !== 'prod') {
      try {
        localStorage.setSessionItem('facebookAuth', JSON.parse(socialButtonData))
      }
      catch (error) {
        logger.warn(error)
      }
    }
  }, [ socialButtonData ])

  useEffect(() => {
    // load auth info if use is authenticated
    if (isReady) {
      if (skipLoginStatus) {
        updateState({
          auth: null,
          isFetching: false,
        })

        return
      }

      fbGetLoginStatus()
        .then((response) => {
          updateState({
            auth: response,
            isFetching: false,
          })
        })
        .catch((error) => {
          updateState({
            auth: null,
            isFetching: false,
          })

          processError(error)
        })
    }
  }, [ updateState, isReady, skipLoginStatus ])

  const login = useCallback(async () => {
    if (!isReady) {
      return
    }

    try {
      updateState({
        isFetching: true,
        auth: null,
      })

      const auth = await fbLogin(device)

      updateState({
        auth,
        isFetching: false,
      })

      return auth
    }
    catch (error) {
      updateState({
        auth: null,
        isFetching: false,
      })

      throw error
    }
  }, [ device, isReady, updateState ])

  return {
    auth,
    login,
    isReady,
    isFetching,
  }
}


export default useFacebookAuth
