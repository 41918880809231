import { useEffect, useState } from 'react'
import config from 'config'
import { useScriptLoader } from 'hooks'
import { SilentError } from 'helpers'
import logger from 'logger'


let facebookSDKInitialization: Promise<boolean> = null

// this function initialize facebook sdk, but does nothing else
const useFacebookSDK = () => {
  const [ isReady, setReady ] = useState(false)

  useEffect(() => {
    if (facebookSDKInitialization) {
      return
    }

    facebookSDKInitialization = new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new SilentError(null, '[Facebook] timeout error'))
      }, 60000)

      window.fbAsyncInit = () => {
        try {
          window.FB.init({
            appId: config.facebookAppId,
            version: 'v19.0',
            xfbml: false, // ATTN uncomment if you need tags support
            status: true,
            cookie: true,
            autoLogAppEvents: true,
          })

          resolve(true)
        }
        catch (error) {
          reject(error)
        }
        finally {
          clearTimeout(timeout)
        }
      }
    })
  }, [])

  useEffect(() => {
    let isMounted = true

    Promise.resolve().then(() => facebookSDKInitialization).then(() => {
      if (isMounted) {
        setReady(true)
      }
    }).catch((error) => {
      logger.warn(error, '[Facebook] init error')
      setReady(false)
    })

    return () => {
      isMounted = false
    }
  }, [])

  useScriptLoader('https://connect.facebook.net/en_US/sdk.js', {
    async: true,
  })

  return {
    isReady,
  }
}


export default useFacebookSDK
